.row{
    display: flex;
    justify-content: space-evenly;
    width:100%;
    margin:4% auto
}
.svg {
 margin-top: -2%;/* Ensure the SVG is above other elements */
}
.groups{
    font-family: "Horev";
    font-size: 8rem;
  direction:rtl;
    color: #ffffff;
    text-align: center;
padding-top:2rem
}
.icon{
    width:10%;
    margin:0 auto;
  }
  .center{
    display: flex;
    justify-content: center;
    margin-top:5%;
  }
  .container{
background:black;
padding-bottom: 2%;;
}
@media only screen and (max-width: 350px) {
    .row{
        display: flex;
        justify-content: space-evenly;
        width:100%;
        margin:4% auto;
        flex-wrap: wrap;
    }
    .svg {
     margin-top: -2%;/* Ensure the SVG is above other elements */
    }
    .groups{
        font-family: "Horev";
        font-size: 4rem;
      direction:rtl;
        color: #ffffff;
        text-align: center;
    padding-top:2rem
    }
    .icon{
        width:30%;
        margin:0 auto;
      }
      .center{
        display: flex;
        justify-content: center;
        margin-top:5%;
      }
      .container{
    background:black;
    padding-bottom: 2%;
    }
}
@media only screen and (min-width: 350px) and (max-width: 450px) {
    .row{
        display: flex;
        justify-content: space-evenly;
        width:100%;
        margin:4% auto;
        flex-wrap: wrap;
    }
    .svg {
     margin-top: -2%;/* Ensure the SVG is above other elements */
    }
    .groups{
        font-family: "Horev";
        font-size: 4.5rem;
      direction:rtl;
        color: #ffffff;
        text-align: center;
    padding-top:2rem
    }
    .icon{
        width:30%;
        margin:0 auto;
      }
      .center{
        display: flex;
        justify-content: center;
        margin-top:5%;
      }
      .container{
    background:black;
    padding-bottom: 2%;
    }
}

@media only screen and (min-width: 450px) and (max-width: 550px) {
    .row{
        display: flex;
        justify-content: space-evenly;
        width:100%;
        margin:4% auto;
        flex-wrap: wrap;
    }
    .svg {
     margin-top: -2%;/* Ensure the SVG is above other elements */
    }
    .groups{
        font-family: "Horev";
        font-size: 5rem;
      direction:rtl;
        color: #ffffff;
        text-align: center;
    padding-top:2rem
    }
    .icon{
        width:30%;
        margin:0 auto;
      }
      .center{
        display: flex;
        justify-content: center;
        margin-top:5%;
      }
      .container{
    background:black;
    padding-bottom: 2%;
    }
}
@media only screen and (min-width: 550px) and (max-width: 750px) {
    .row{
        display: flex;
        justify-content: space-evenly;
        width:100%;
        margin:4% auto;
        flex-wrap: wrap;
    }
    .svg {
     margin-top: -2%;/* Ensure the SVG is above other elements */
    }
    .groups{
        font-family: "Horev";
        font-size: 6rem;
      direction:rtl;
        color: #ffffff;
        text-align: center;
    padding-top:2rem
    }
    .icon{
        width:20%;
        margin:0 auto;
      }
      .center{
        display: flex;
        justify-content: center;
        margin-top:5%;
      }
      .container{
    background:black;
    padding-bottom: 2%;
    }
}
@media only screen and (min-width: 750px) and (max-width: 950px) {
    .row{
        display: flex;
        justify-content: space-evenly;
        width:100%;
        margin:4% auto;
        flex-wrap: wrap;
    }
    .svg {
     margin-top: -2%;/* Ensure the SVG is above other elements */
    }
    .groups{
        font-family: "Horev";
        font-size: 6.5rem;
      direction:rtl;
        color: #ffffff;
        text-align: center;
    padding-top:2rem
    }
    .icon{
        width:20%;
        margin:0 auto;
      }
      .center{
        display: flex;
        justify-content: center;
        margin-top:5%;
      }
      .container{
    background:black;
    padding-bottom: 2%;
    }
}

@media only screen and (min-width: 950px) and (max-width: 1250px) {
    .row{
        display: flex;
        justify-content: space-evenly;
        width:100%;
        margin:4% auto;
        flex-wrap: wrap;
    }
    .svg {
     margin-top: -2%;/* Ensure the SVG is above other elements */
    }
    .groups{
        font-family: "Horev";
        font-size: 7rem;
      direction:rtl;
        color: #ffffff;
        text-align: center;
    padding-top:2rem
    }
    .icon{
        width:15%;
        margin:0 auto;
      }
      .center{
        display: flex;
        justify-content: center;
        margin-top:5%;
      }
      .container{
    background:black;
    padding-bottom: 2%;
    }
}

@media only screen and (min-width: 1250px) and (max-width: 1400px) {
    .row{
        display: flex;
        justify-content: space-evenly;
        width:100%;
        margin:4% auto;
        flex-wrap: wrap;
    }
    .svg {
     margin-top: -2%;/* Ensure the SVG is above other elements */
    }
    .groups{
        font-family: "Horev";
        font-size: 7.5rem;
      direction:rtl;
        color: #ffffff;
        text-align: center;
    padding-top:2rem
    }
    .icon{
        width:15%;
        margin:0 auto;
      }
      .center{
        display: flex;
        justify-content: center;
        margin-top:5%;
      }
      .container{
    background:black;
    padding-bottom: 2%;
    }
}

