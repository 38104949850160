.stickyNavbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100dvw;
  height: 10vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: none; /* Glass background effect */
  backdrop-filter: blur(20px); /* Adjust the blur value based on your preference */
  padding: 0 20px;
z-index: 10;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1); /* Classy box shadow */
  overflow-x: hidden;
}

.navLogo {
  font-size: 1.5rem;
  font-weight: bold;
  margin-left:50px;
  color: rgb(0, 0, 0); /* Adjust the color of your logo */
}
.logo{
width:15vw;
object-fit: contain;
}
.navLinks {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.navLink {
  margin-right: 50px;
  text-decoration: none;
  color: rgb(0, 0, 0); /* Adjust the color of your navigation links */
  font-family: "Heebo";
  cursor: pointer;

  transition: all ease-out 1s;
}
.navLink:hover{
  color: rgb(157, 67, 42);
  transform: scale(1.1);
}
@media only screen and (max-width: 350px){

  .stickyNavbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100dvw;
    height: 10vh;
    display: flex;
 
    justify-content: center;
    align-items: center;
    background: none; /* Glass background effect */
    backdrop-filter: blur(20px); /* Adjust the blur value based on your preference */
 
  z-index: 10;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1); /* Classy box shadow */
  }
  .smallNav{
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    width: 100dvw;
    backdrop-filter: blur(20px); /* Adjust the blur value based on your preference */
 
    z-index: 10;
    height: 10vh;
    background: none; 
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1); /* Classy box shadow */
    align-items: center;
  }



}
@media only screen and (min-width: 350px) and (max-width: 550px){
  .smallNav{
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    width: 100dvw;
    backdrop-filter: blur(20px); /* Adjust the blur value based on your preference */
 
    z-index: 10;
    height: 10vh;
    background: none; 
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1); /* Classy box shadow */
    align-items: center;
  }
  .logo{
  height:80%;
  margin :auto 0;
    object-fit: contain;
      }
}
@media only screen and (min-width: 550px) and (max-width: 850px){
  .smallNav{
    justify-content: space-between;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    width: 100dvw;
    backdrop-filter: blur(20px); /* Adjust the blur value based on your preference */
 
    z-index: 10;
    height: 10vh;
    background: none; 
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1); /* Classy box shadow */
    align-items: center;
  }
  .logo{
    width:8vw;
    object-fit: contain;
    margin-right:25%;
      }
}
@media only screen and (min-width: 850px) and (max-width: 1050px){
  .stickyNavbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 10vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: none; /* Glass background effect */
    backdrop-filter: blur(20px); /* Adjust the blur value based on your preference */
    padding: 0 20px;
  z-index: 10;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1); /* Classy box shadow */
  }

  .navLogo {
    font-size: 1.5rem;
    font-weight: bold;
 
    color: rgb(0, 0, 0); /* Adjust the color of your logo */
  }
  .logo{
width:15vw;
object-fit: contain;
  }
  .navLinks {
    display: flex;
    list-style: none;
    align-items: center;

 
    padding: 0;
  }
  
  .navLink {
    margin-right: 40px;

    text-decoration: none;
    color: rgb(0, 0, 0); /* Adjust the color of your navigation links */
    font-family: "Heebo";
    cursor: pointer;
  
    transition: all ease-out 1s;
  }
  .navLink:hover{
    color: rgb(157, 67, 42);
    transform: scale(1.1);
  }
}