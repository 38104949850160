.container{

  width:100%;
  height:55vh;
  display: flex;
  flex-direction: row;
  
  direction:rtl;
  justify-content: space-evenly;
  }
  .image{
  width:30%;
  height:70%;
  margin:auto 0;
  object-fit: contain;
  border-radius: 50%;
  }
  
  .column{
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      justify-content: center;
      width:45%;
      margin:0 auto;
  }
  .name{
      font-family: "Horev";
      font-size: 5rem;
      text-align: center;
      margin: 0 auto;
  }
  .description{
      font-family: "HeeboR";
      font-size: 1.2rem;
      text-align: center;
      width:65%;
  }
  
  .logo{
      width:50%;
  }
  .go{
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      width:30%;
      margin:0 auto;
      align-items: center;
  }
  @media only screen and (max-width: 350px)  {
      .container{
  
          width:100%;
          height:85vh;
          display: flex;
          flex-direction: column;
          
          direction:rtl;
          justify-content: space-evenly;
          }
          .image{
          width:70%;
          height:30%;
          margin:0 auto;
          object-fit: contain;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          }
          
          .column{
              display: flex;
              flex-direction: column;
              justify-content: space-evenly;
              align-items: center;
              justify-content: center;
              width:85%;
              margin:0 auto;
          }
          .name{
              font-family: "Horev";
              font-size: 3rem;
              text-align: center;
              margin: 0 auto;
          }
          .description{
              font-family: "HeeboR";
              font-size: 0.9rem;
              text-align: center;
              width:95%;
          }
          
          .logo{
              width:100%;
          }
          .go{
              display: flex;
              flex-direction: column;
              justify-content: space-around;
              width:30%;
              margin:0 auto;
              align-items: center;
          }
  }
  @media only screen and (min-width: 350px) and (max-width: 450px)  {
      .container{
  
          width:100%;
          height:95vh;
          display: flex;
          flex-direction: column;
          
          direction:rtl;
          justify-content: space-evenly;
          }
          .image{
          width:70%;
          height:30%;
          margin:0 auto;
          object-fit: contain;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          }
          
          .column{
              display: flex;
              flex-direction: column;
              justify-content: space-evenly;
              align-items: center;
              justify-content: center;
              width:85%;
              margin:0 auto;
          }
          .name{
              font-family: "Horev";
              font-size: 3rem;
              text-align: center;
              margin: 0 auto;
          }
          .description{
              font-family: "HeeboR";
              font-size: 1rem;
              text-align: center;
              width:95%;
          }
          
          .logo{
              width:100%;
          }
          .go{
              display: flex;
              flex-direction: column;
              justify-content: space-around;
              width:30%;
              margin:0 auto;
              align-items: center;
          }
  }
  @media only screen and (min-width: 450px) and (max-width: 550px) {
      .container{
          width:100%;
          height:90vh;
          display: flex;
          flex-direction: column;
          
          direction:rtl;
          justify-content: space-evenly;
          }
          .image{
          width:70%;
          height:30%;
          margin:0 auto;
          object-fit: contain;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          }
          
          .column{
              display: flex;
              flex-direction: column;
              justify-content: space-evenly;
              align-items: center;
              justify-content: center;
              width:85%;
              margin:0 auto;
          }
          .name{
              font-family: "Horev";
              font-size: 3.5rem;
              text-align: center;
              margin: 0 auto;
          }
          .description{
              font-family: "HeeboR";
              font-size: 1.1rem;
              text-align: center;
              width:95%;
          }
          
          .logo{
              width:100%;
          }
          .go{
              display: flex;
              flex-direction: column;
              justify-content: space-around;
              width:30%;
              margin:0 auto;
              align-items: center;
          }
  }
  @media only screen and (min-width: 550px) and (max-width: 750px) {
      .container{
          width:70%;
          height:100vh;
          display: flex;
          flex-direction: column;
          margin: 0 auto;
          direction:rtl;
          justify-content: space-evenly;
          }
          .image{
          width:70%;
          height:30%;
          margin:0 auto;
          object-fit: contain;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          }
          
          .column{
              display: flex;
              flex-direction: column;
              justify-content: space-evenly;
              align-items: center;
              justify-content: center;
              width:95%;
              margin:0 auto;
          }
          .name{
              font-family: "Horev";
              font-size: 3.5rem;
              text-align: center;
              margin: 0 auto;
          }
          .description{
              font-family: "HeeboR";
              font-size: 1.1rem;
              text-align: center;
              width:95%;
          }
          
          .logo{
              width:100%;
          }
          .go{
              display: flex;
              flex-direction: column;
              justify-content: space-around;
              width:30%;
              margin:0 auto;
              align-items: center;
          }
  }
  @media only screen and (min-width: 750px) and (max-width: 850px) {
      .container{
          width:60%;
          height:100vh;
          display: flex;
          flex-direction: column;
          margin: 0 auto;
          direction:rtl;
          justify-content: space-evenly;
          }
          .image{
          width:70%;
          height:30%;
          margin:0 auto;
          object-fit: contain;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          }
          
          .column{
              display: flex;
              flex-direction: column;
              justify-content: space-evenly;
              align-items: center;
              justify-content: center;
              width:95%;
              margin:0 auto;
          }
          .name{
              font-family: "Horev";
              font-size: 3.5rem;
              text-align: center;
              margin: 0 auto;
          }
          .description{
              font-family: "HeeboR";
              font-size: 1.1rem;
              text-align: center;
              width:100%;
          }
          
          .logo{
              width:100%;
          }
          .go{
              display: flex;
              flex-direction: column;
              justify-content: space-around;
              width:30%;
              margin:0 auto;
              align-items: center;
          }
  }
  @media only screen and (min-width: 850px) and (max-width: 1050px) {
      .container{
  
          width:100%;
          height:50vh;
          display: flex;
          flex-direction: row;
          
          direction:rtl;
          justify-content: space-evenly;
          }
          .image{
          width:30%;
          height:70%;
          margin:auto 0;
          object-fit: contain;
          border-radius: 50%;
          }
          
          .column{
              display: flex;
              flex-direction: column;
              justify-content: space-evenly;
              align-items: center;
              justify-content: center;
              width:45%;
              margin:0 auto;
          }
          .name{
              font-family: "Horev";
              font-size: 3.5rem;
              text-align: center;
              margin: 0 auto;
          }
          .description{
              font-family: "HeeboR";
              font-size: 1rem;
              text-align: center;
              width:75%;
          }
          
          .logo{
              width:50%;
          }
          .go{
              display: flex;
              flex-direction: column;
              justify-content: space-around;
              width:30%;
              margin:0 auto;
              align-items: center;
          }
  }
  @media only screen and (min-width:1050px) and (max-width: 1400px) {
      .container{
  
          width:100%;
          height:50vh;
          display: flex;
          flex-direction: row;
          
          direction:rtl;
          justify-content: space-evenly;
          }
          .image{
          width:30%;
          height:70%;
          margin:auto 0;
          object-fit: contain;
          border-radius: 50%;
          }
          
          .column{
              display: flex;
              flex-direction: column;
              justify-content: space-evenly;
              align-items: center;
              justify-content: center;
              width:45%;
              margin:0 auto;
          }
          .name{
              font-family: "Horev";
              font-size: 4rem;
              text-align: center;
              margin: 0 auto;
          }
          .description{
              font-family: "HeeboR";
              font-size: 1.1rem;
              text-align: center;
              width:90%;
          }
          
          .logo{
              width:50%;
          }
          .go{
              display: flex;
              flex-direction: column;
              justify-content: space-around;
              width:30%;
              margin:0 auto;
              align-items: center;
          }
  }
  
  