.title {
    font-family: "Horev";
    font-size: 9rem;
  direction:rtl;
    color: #000000;
    text-align: center;


  }
.image{
  width:25%;
  object-fit: contain;

}
.center{
  display: flex;
  justify-content: center;
  margin:3% auto;
}
  .bold{
    display: inline;
    color: #9f0000;
  }
  .subTitle{
    font-family: "Horev";
    font-size: 4rem;
    text-align: center;
    width:80%;
    margin:3% auto;
    direction: rtl;
  }
  .container{
/* background: #f5f3f3; */
width:100%;
  }
.svg{
  margin-top:-2%;
}
  @media only screen and (max-width: 350px) {

    .title {
        font-family: "Horev";
        font-size: 4rem;
      direction:rtl;
        color: #000000;
        text-align: center;
    
    
      }
      .image{
        width:75%;
        object-fit: contain;
      
      }
      .bold{
        display: inline;
        color: #9f0000;
      }
      .subTitle{
        font-family: "Horev";
        font-size: 2.5rem;
        text-align: center;
        width:85%;
        margin:3% auto;
        direction: rtl;
      }
      .container{
    /* background: #f5f3f3; */
    width:100%;
      }
    

  }
  @media only screen  and (min-width: 350px) and (max-width: 450px) {

    .title {
        font-family: "Horev";
        font-size: 4.5rem;
      direction:rtl;
        color: #000000;
        text-align: center;
    
    
      }
      .image{
        width:55%;
        object-fit: contain;
          margin:2% auto;
      }
      .bold{
        display: inline;
        color: #9f0000;
      }
      .subTitle{
        font-family: "Horev";
        font-size: 2.5rem;
        text-align: center;
        width:85%;
        margin:3% auto;
        direction: rtl;
      }
      .container{
    /* background: #f5f3f3; */
    width:100%;
      }
  }
  @media only screen  and (min-width: 450px) and (max-width:550px) {

    .title {
        font-family: "Horev";
        font-size: 5rem;
      direction:rtl;
        color: #000000;
        text-align: center;
    
    
      }
      .image{
        width:45%;
        object-fit: contain;
      
      }
      .bold{
        display: inline;
        color: #9f0000;
      }
      .subTitle{
        font-family: "Horev";
        font-size: 3rem;
        text-align: center;
        width:85%;
        margin:3% auto;
        direction: rtl;
      }
      .container{
    /* background: #f5f3f3; */
    width:100%;
      }
  }
  @media only screen  and (min-width: 550px) and (max-width:750px) {

    .title {
        font-family: "Horev";
        font-size: 6rem;
      direction:rtl;
        color: #000000;
        text-align: center;
      }
      .image{
        width:40%;
        object-fit: contain;
      
      }
      .bold{
        display: inline;
        color: #9f0000;
      }
      .subTitle{
        font-family: "Horev";
        font-size: 3rem;
        text-align: center;
        width:85%;
        margin:3% auto;
        direction: rtl;
      }
      .container{
    /* background: #f5f3f3; */
    width:100%;
      }
  }
  @media only screen  and (min-width: 750px) and (max-width:950px) {

    .title {
        font-family: "Horev";
        font-size: 6.5rem;
      direction:rtl;
        color: #000000;
        text-align: center;
    
    
      }
      .image{
        width:40%;
        object-fit: contain;
      
      }
      .bold{
        display: inline;
        color: #9f0000;
      }
      .subTitle{
        font-family: "Horev";
        font-size: 3.5rem;
        text-align: center;
        width:85%;
        margin:3% auto;
        direction: rtl;
      }
      .container{
    /* background: #f5f3f3; */
    width:100%;
      }
  }
  @media only screen  and (min-width: 950px) and (max-width:1250px) {

    .title {
        font-family: "Horev";
        font-size: 7rem;
      direction:rtl;
        color: #000000;
        text-align: center;
    
    
      }
      .image{
        width:35%;
        object-fit: contain;
      
      }
      .bold{
        display: inline;
        color: #9f0000;
      }
      .subTitle{
        font-family: "Horev";
        font-size: 3rem;
        text-align: center;
        width:85%;
        margin:3% auto;
        direction: rtl;
      }
      .container{
    /* background: #f5f3f3; */
    width:100%;
      }
  }
  @media only screen  and (min-width: 1250px) and (max-width:1450px) {

    .title {
        font-family: "Horev";
        font-size: 7.5rem;
      direction:rtl;
        color: #000000;
        text-align: center;
    
    
      }
      .bold{
        display: inline;
        color: #9f0000;
      }
      .image{
        width:30%;
        object-fit: contain;
      
      }
      .subTitle{
        font-family: "Horev";
        font-size: 3.5rem;
        text-align: center;
        width:85%;
        margin:3% auto;
        direction: rtl;
      }
      .container{
    /* background: #f5f3f3; */
    width:100%;
      }
  }