.background {
  height: 100vh; 
  width: 100vw;
  background-image: url("../images/backgroundComputers.png");
  background-size: cover;
  background-position: center;
  overflow-x: hidden;

  }
  
  .title {
    font-family: "Horev";
    font-size: 9rem;
    direction:rtl;
    color: #000000;
    text-align: center;
    padding-top: 5rem;
    margin-top: 100px;
  }
  .bold{
    display: inline;
    color: #9f0000;
  }
  .icon{
    width:15%;
    margin:0 auto;
  }
  
  .subTitle {
    font-family: "Horev";
    font-size: 5rem;
    color: #000000;
    text-align: center;
    margin-top:10px
  }
  @media only screen and (max-width: 350px) {

    .background {
  

      background-image: url("../images/backgroundPhone.png");
      background-size: cover;
      background-position: center top;
      background-repeat: no-repeat;
      overflow-x: hidden;
      }
      
      .title {
        font-family: "Horev";
        font-size: 6rem;
      direction:rtl;
        color: #000000;
        text-align: center;
        padding-top: 5rem;
      }
      .bold{
        display: inline;
        color: #9f0000;
      }
      .icon{
        width:40%;
        margin:0 auto;
      }
      
      .subTitle {
        font-family: "Horev";
        font-size: 3rem;
        color: #000000;
        text-align: center;
        margin-top:10px
      }

  }
  @media only screen and (min-width: 350px)  and (max-width:450px)  {

    .background {
  
   height:90dvh;
     background-image: url("../images/backgroundPhone1.png");
      background-size: cover;
      background-position: center top;
      background-repeat: no-repeat;
      overflow-x: hidden;
      }
      
      .title {
        font-family: "Horev";
        font-size: 6.5rem;
      direction:rtl;
        color: #000000;
        text-align: center;
        padding-top: 5.3rem;
      }
      .bold{
        display: inline;
        color: #9f0000;
      }
      .icon{
        width:35%;
        margin:0 auto;
      }
      
      .subTitle {
        font-family: "Horev";
        font-size: 3rem;
        color: #000000;
        text-align: center;
        margin-top:10px
      }
  }
  @media only screen and (min-width: 450px)  and (max-width:550px)  {

    .background {
  
     height:100vh;
     background-image: url("../images/backgroundbiggerPhones.png");
     background-size: cover;
      background-position: center top;
      background-repeat: no-repeat;
      overflow-x: hidden;
      }
      
      .title {
        font-family: "Horev";
        font-size: 6rem;
      direction:rtl;
        color: #000000;
        text-align: center;
        padding-top: 6rem;
      }
      .bold{
        display: inline;
        color: #9f0000;
      }
      .icon{
        width:30%;
        margin:0 auto;
      }
      
      .subTitle {
        font-family: "Horev";
        font-size: 3.5rem;
        color: #000000;
        text-align: center;
        margin-top:10px
      }
  }
  @media only screen and (min-width: 550px)  and (max-width:650px)  {

    .background {
  
     height:100vh;
     background-image: url("../images/backgroundTablets.png");
      background-size: cover;
      background-position: center top;
      background-repeat: no-repeat;
      overflow-x: hidden;
      }
      
      .title {
        font-family: "Horev";
        font-size: 6.5rem;
        direction:rtl;
        color: #000000;
        text-align: center;
        padding-top: 7rem;
      }
      .bold{
        display: inline;
        color: #9f0000;
      }
      .icon{
        width:30%;
        margin:0 auto;
      }
      
      .subTitle {
        font-family: "Horev";
        font-size: 3.5rem;
        color: #000000;
        text-align: center;
        margin-top:10px
      }
  }
  @media only screen and (min-width: 650px)  and (max-width:850px)  {

    .background {
  
     height:100vh;
      background-image: url("../images/backgroundTablets.png");
      background-size: cover;
      background-position: center top;
      background-repeat: no-repeat;
      overflow-x: hidden;
      }
      
      .title {
        font-family: "Horev";
        font-size: 6.5rem;
        direction:rtl;
        color: #000000;
        text-align: center;
        padding-top: 6rem;
      }
      .bold{
        display: inline;
        color: #9f0000;
      }
      .icon{
        width:25%;
        margin:0 auto;
      }
      
      .subTitle {
        font-family: "Horev";
        font-size: 3.5rem;
        color: #000000;
        text-align: center;
        margin-top:10px
      }
  }
  @media only screen and (min-width: 850px)  and (max-width:1050px)  {

    .background {
  
     height:100vh;
      background-image: url("../images/backgroundComputers.png");
      background-size: cover;
      background-position: center top;
      background-repeat: no-repeat;
      overflow-x: hidden;
      }
      
      .title {
        font-family: "Horev";
        font-size: 7rem;
        direction:rtl;
        color: #000000;
        text-align: center;
        padding-top: 5.5rem;
      }
      .bold{
        display: inline;
        color: #9f0000;
      }
      .icon{
        width:20%;
        margin:0 auto;
      }
      
      .subTitle {
        font-family: "Horev";
        font-size: 4rem;
        color: #000000;
        text-align: center;
        margin-top:10px
      }
  }
  @media only screen and (min-width: 1050px)  and (max-width:1250px)  {

    .background {
  
     height:100dvh;
     background-image: url("../images/backgroundComputers.png");
      background-size: cover;
      background-position: center top;
      background-repeat: no-repeat;
      overflow-x: hidden;
      }
      
      .title {
        font-family: "Horev";
        font-size: 8rem;
        direction:rtl;
        color: #000000;
        text-align: center;
        padding-top: 4.5rem;
      }
      .bold{
        display: inline;
        color: #9f0000;
      }
      .icon{
        width:18%;
        margin:0 auto;
      }
      
      .subTitle {
        font-family: "Horev";
        font-size: 4.5rem;
        color: #000000;
        text-align: center;
        margin-top:10px
      }
  }
  @media only screen and (min-width: 1250px)  and (max-width:1450px)  {

    .background {
  
     height:100dvh;
     background-image: url("../images/backgroundComputers.png");
      background-size: cover;
      background-position: center top;
      background-repeat: no-repeat;
      overflow-x: hidden;
      }
      
      .title {
        font-family: "Horev";
        font-size: 8.5rem;
        direction:rtl;
        color: #000000;
        text-align: center;
        padding-top: 5rem;
      }
      .bold{
        display: inline;
        color: #9f0000;
      }
      .icon{
        width:16%;
        margin:0 auto;
      }
      
      .subTitle {
        font-family: "Horev";
        font-size: 4.5rem;
        color: #000000;
        text-align: center;
        margin-top:10px
      }
  }