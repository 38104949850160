.title {
  font-family: "Horev";
  font-size: 8rem;
direction:rtl;
  color: #000000;
  text-align: center;

padding-top:2rem;
}
.label{

  font-family: "Heebo";
  margin-top:1%

}
.description{

  font-family: "HeeboR";
  
}
.sliderContainer {

  width:85%;
  margin:3% auto;
height:auto;
  justify-content: center;
  align-items: center;
  padding: 5%;;
}

.prev,
.next {
  background-color: #000;
  color: #fff;
  padding: 10px;
}
.row{
  display: flex;
  width: 100%;
  flex-direction: row;
  margin:3% auto;
  justify-content: space-evenly;
}
.bold{
  display: inline;
  color: #9f0000;
}
.fear{
  font-family: "Horev";
  font-size: 4rem;
  text-align: center;
  width:80%;
  margin:3% auto
}
.go{
  font-family: "Horev";
  font-size: 6rem;
  text-align: center;
  width:80%;
  direction: rtl;
  margin:3% auto

}

@media only screen and (max-width: 350px) {
  .title {
      font-family: "Horev";
      font-size:4rem;
    direction:rtl;
      color: #000000;
      text-align: center;
  
  padding-top:2rem;
    }
    .sliderContainer {

      width:90%;
      margin:3% auto;
   height: auto;
      justify-content: center;
      align-items: center;
    }
    .row{
      display: flex;
      width: 100%;
      flex-direction: row;
      margin:2% auto;
      flex-wrap: wrap;
      justify-content: space-evenly;
    }
    .bold{
      display: inline;
      color: #9f0000;
    }
    .fear{
      font-family: "Horev";
      font-size: 2.5rem;
      text-align: center;
      width:80%;
      margin:5% auto
    }
    .go{
      font-family: "Horev";
      font-size: 3.5rem;
      text-align: center;
      width:80%;
      direction: rtl;
      margin:3% auto
  
    }

}
@media only screen and (min-width: 350px) and (max-width: 450px)  {
  .title {
      font-family: "Horev";
      font-size:4.5rem;
    direction:rtl;
      color: #000000;
      text-align: center;
  
  padding-top:2rem;
    }
    .slick-arrow {
      display: flex !important; /* Use !important to override any existing styles */
      background:red;
      z-index: 999;
    }
    .sliderContainer {

      width:85%;
      margin:3% auto;
      height: auto;
      justify-content: center;
      align-items: center;
    }
    .row{
      display: flex;
      width: 100%;
      flex-direction: row;
      margin:2% auto;
      flex-wrap: wrap;
      justify-content: space-evenly;
    }
    .bold{
      display: inline;
      color: #9f0000;
    }
    .fear{
      font-family: "Horev";
      font-size: 2.5rem;
      text-align: center;
      width:80%;
      margin:5% auto
    }
    .go{
      font-family: "Horev";
      font-size: 3.5rem;
      text-align: center;
      width:80%;
      direction: rtl;
      margin:3% auto
  
    }
}
@media only screen and (min-width: 450px) and (max-width: 550px)  {
  .title {
      font-family: "Horev";
      font-size:4.5rem;
    direction:rtl;
      color: #000000;
      text-align: center;
  
  padding-top:2rem;
    }
    .sliderContainer {

      width:80%;
      margin:3% auto;
      height: auto;
      justify-content: center;
      align-items: center;
    }
    .row{
      display: flex;
      width: 100%;
      flex-direction: row;
      margin:2% auto;
      flex-wrap: wrap;
      justify-content: space-evenly;
    }
    .bold{
      display: inline;
      color: #9f0000;
    }
    .fear{
      font-family: "Horev";
      font-size: 3rem;
      text-align: center;
      width:80%;
      margin:5% auto
    }
    .go{
      font-family: "Horev";
      font-size: 4rem;
      text-align: center;
      width:80%;
      direction: rtl;
      margin:3% auto
  
    }
}
@media only screen and (min-width: 550px) and (max-width: 750px)  {
  .title {
      font-family: "Horev";
      font-size:5rem;
      direction:rtl;
      color: #000000;
      text-align: center;
      padding-top:2rem;
    }
    .sliderContainer {

      width:75%;
      margin:3% auto;
      height: auto;
      justify-content: center;
      align-items: center;
    }
    .row{
      display: flex;
      width: 100%;
      flex-direction: row;
      margin:2% auto;
      flex-wrap: wrap;
      justify-content: space-evenly;
    }
    .bold{
      display: inline;
      color: #9f0000;
    }
    .fear{
      font-family: "Horev";
      font-size: 3.5rem;
      text-align: center;
      width:80%;
      margin:3% auto
    }
    .go{
      font-family: "Horev";
      font-size: 5rem;
      text-align: center;
      width:80%;
      direction: rtl;
      margin:3% auto
  
    }
}
@media only screen and (min-width: 750px) and (max-width: 950px)  {
  .title {
      font-family: "Horev";
      font-size:6rem;
      direction:rtl;
      color: #000000;
      text-align: center;
      padding-top:2rem;
    }
    .sliderContainer {

      width:75%;
      margin:3% auto;
      height: auto;
      justify-content: center;
      align-items: center;
    }
    .row{
      display: flex;
      width: 100%;
      flex-direction: row;
      margin:2% auto;
      flex-wrap: wrap;
      justify-content: space-evenly;
    }
    .bold{
      display: inline;
      color: #9f0000;
    }
    .fear{
      font-family: "Horev";
      font-size: 3.5em;
      text-align: center;
      width:80%;
      margin:3% auto
    }
    .go{
      font-family: "Horev";
      font-size: 5rem;
      text-align: center;
      width:80%;
      direction: rtl;
      margin:3% auto;
    }
}
@media only screen and (min-width: 950px) and (max-width: 1150px)  {
  .title {
      font-family: "Horev";
      font-size:6.5rem;
      direction:rtl;
      color: #000000;
      text-align: center;
      padding-top:2rem;
    }
    .sliderContainer {

      width:70%;
      margin:3% auto;
      height: auto;
      justify-content: center;
      align-items: center;
    }
    .row{
      display: flex;
      width: 100%;
      flex-direction: row;
      margin:2% auto;
      flex-wrap: wrap;
      justify-content: space-evenly;
    }
    .bold{
      display: inline;
      color: #9f0000;
    }
    .fear{
      font-family: "Horev";
      font-size: 3rem;
      text-align: center;
      width:80%;
      margin:3% auto
    }
    .go{
      font-family: "Horev";
      font-size: 5rem;
      text-align: center;
      width:80%;
      direction: rtl;
      margin:3% auto;
    }
}