.a{
    text-decoration: none;
    color:#9f0000;
    font-size: 1.3rem;
    padding: 1%;
  }
  
  .byMe{
   
      text-align:center;
  
      margin:0 auto;
      color: rgb(0, 0, 0);
      font-size: 1rem;
      font-family: "HeeboR";
  }