.group{
  display: flex;
  flex-direction: row;
  direction: rtl;
  justify-content: space-evenly;
  background: linear-gradient(180deg, rgba(69,12,0,1) 0%, rgba(50,6,0,0.9019257361147583) 50%, rgba(69,12,0,0.8543066884957108) 100%);
  border-radius: 10% 0 10% 0;
  box-shadow: 0 0 9px 2px rgb(255, 255, 255);
  width:35vw;
  height:30vh;
  align-items: center;
}
.city{
  font-family: "Horev";
  font-size: 7rem;
  color: #ffffff;
  text-align: center;
  
}
.day{
  font-family: "Horev";
  font-size: 3rem;
  color: #ffffff;
  margin-left:1rem;
  text-align: center;
}
.hour{
  font-family: "Horev";
  font-size: 2.5rem;
  color: #ffffff;
  text-align: center;
}
.data{
  display: flex;
  flex-direction: row; 
  align-items: center;
 
  justify-content: space-evenly;
  
}
@media only screen and (max-width: 350px)   {


  .group{
      display: flex;
      flex-direction: row;
      direction: rtl;
      justify-content: space-evenly;
      background: linear-gradient(180deg, rgba(69,12,0,1) 0%, rgba(50,6,0,0.9019257361147583) 50%, rgba(69,12,0,0.8543066884957108) 100%);
      border-radius: 5% 0 5% 0;
      box-shadow: 0 0 5px 2px rgb(255, 255, 255);
      width:85dvw;
      height:25dvh;
      align-items: center;
      margin:3% auto;
  }
  .city{
      font-family: "Horev";
      font-size: 4rem;
      color: #ffffff;
      text-align: center;
      
  }
  .day{
      font-family: "Horev";
      font-size: 2rem;
      color: #ffffff;
      margin-left:1rem;
      text-align: center;
  }
  .hour{
      font-family: "Horev";
      font-size: 1.5rem;
      color: #ffffff;
      text-align: center;
  }
  .data{
      display: flex;
      flex-direction: row; 
      align-items: center;
     
      justify-content: space-evenly;
      
  }

}
@media only screen and (min-width: 350px) and (max-width: 450px)   {


  .group{
      display: flex;
      flex-direction: row;
      direction: rtl;
      justify-content: space-evenly;
      background: linear-gradient(180deg, rgba(69,12,0,1) 0%, rgba(50,6,0,0.9019257361147583) 50%, rgba(69,12,0,0.8543066884957108) 100%);
      border-radius: 5% 0 5% 0;
      box-shadow: 0 0 5px 2px rgb(255, 255, 255);
      width:85dvw;
      height:25dvh;
      align-items: center;
      margin:3% auto;
  }
  .city{
      font-family: "Horev";
      font-size: 4.5rem;
      color: #ffffff;
      text-align: center;
      
  }
  .day{
      font-family: "Horev";
      font-size: 2.5rem;
      color: #ffffff;
      margin-left:1rem;
      text-align: center;
  }
  .hour{
      font-family: "Horev";
      font-size: 2rem;
      color: #ffffff;
      text-align: center;
  }
  .data{
      display: flex;
      flex-direction: row; 
      align-items: center;
     
      justify-content: space-evenly;
      
  }

}
@media only screen and (min-width: 450px) and (max-width: 550px)   {


  .group{
      display: flex;
      flex-direction: row;
      direction: rtl;
      justify-content: space-evenly;
      background: linear-gradient(180deg, rgba(69,12,0,1) 0%, rgba(50,6,0,0.9019257361147583) 50%, rgba(69,12,0,0.8543066884957108) 100%);
      border-radius: 5% 0 5% 0;
      box-shadow: 0 0 5px 2px rgb(255, 255, 255);
      width:85dvw;
      height:25dvh;
      align-items: center;
      margin:3% auto;
  }
  .city{
      font-family: "Horev";
      font-size: 4.5rem;
      color: #ffffff;
      text-align: center;
      
  }
  .day{
      font-family: "Horev";
      font-size: 2.5rem;
      color: #ffffff;
      margin-left:1rem;
      text-align: center;
  }
  .hour{
      font-family: "Horev";
      font-size: 2rem;
      color: #ffffff;
      text-align: center;
  }
  .data{
      display: flex;
      flex-direction: row; 
      align-items: center;
     
      justify-content: space-evenly;
      
  }

}
@media only screen and (min-width: 550px) and (max-width: 650px)   {


  .group{
      display: flex;
      flex-direction: row;
      direction: rtl;
      justify-content: space-evenly;
      background: linear-gradient(180deg, rgba(69,12,0,1) 0%, rgba(50,6,0,0.9019257361147583) 50%, rgba(69,12,0,0.8543066884957108) 100%);
      border-radius: 5% 0 5% 0;
      box-shadow: 0 0 5px 2px rgb(255, 255, 255);
      width:70dvw;
      height:25dvh;
      align-items: center;
      margin:3% auto;
  }
  .city{
      font-family: "Horev";
      font-size: 4.5rem;
      color: #ffffff;
      text-align: center;
      
  }
  .day{
      font-family: "Horev";
      font-size: 2.5rem;
      color: #ffffff;
      margin-left:1rem;
      text-align: center;
  }
  .hour{
      font-family: "Horev";
      font-size: 2rem;
      color: #ffffff;
      text-align: center;
  }
  .data{
      display: flex;
      flex-direction: row; 
      align-items: center;
      justify-content: space-evenly;
  }
}
@media only screen and (min-width: 650px) and (max-width: 750px)   {


  .group{
      display: flex;
      flex-direction: row;
      direction: rtl;
      justify-content: space-evenly;
      background: linear-gradient(180deg, rgba(69,12,0,1) 0%, rgba(50,6,0,0.9019257361147583) 50%, rgba(69,12,0,0.8543066884957108) 100%);
      border-radius: 5% 0 5% 0;
      box-shadow: 0 0 5px 2px rgb(255, 255, 255);
      width:70dvw;
      height:30dvh;
      align-items: center;
      margin:3% auto;
  }
  .city{
      font-family: "Horev";
      font-size: 5rem;
      color: #ffffff;
      text-align: center;
      
  }
  .day{
      font-family: "Horev";
      font-size: 3rem;
      color: #ffffff;
      margin-left:1rem;
      text-align: center;
  }
  .hour{
      font-family: "Horev";
      font-size: 2.5rem;
      color: #ffffff;
      text-align: center;
  }
  .data{
      display: flex;
      flex-direction: row; 
      align-items: center;
      justify-content: space-evenly;
  }
}
@media only screen and (min-width: 750px) and (max-width: 950px)   {


  .group{
      display: flex;
      flex-direction: row;
      direction: rtl;
      justify-content: space-evenly;
      background: linear-gradient(180deg, rgba(69,12,0,1) 0%, rgba(50,6,0,0.9019257361147583) 50%, rgba(69,12,0,0.8543066884957108) 100%);
      border-radius: 5% 0 5% 0;
      box-shadow: 0 0 5px 2px rgb(255, 255, 255);
      width:45dvw;
      height:30dvh;
      align-items: center;
      margin:3% auto;
  }
  .city{
      font-family: "Horev";
      font-size: 4.5rem;
      color: #ffffff;
      text-align: center;
      
  }
  .day{
      font-family: "Horev";
      font-size: 2.5rem;
      color: #ffffff;
      margin-left:1rem;
      text-align: center;
  }
  .hour{
      font-family: "Horev";
      font-size: 2rem;
      color: #ffffff;
      text-align: center;
  }
  .data{
      display: flex;
      flex-direction: row; 
      align-items: center;
      justify-content: space-evenly;
  }
}
@media only screen and (min-width: 950px) and (max-width: 1150px)   {


  .group{
      display: flex;
      flex-direction: row;
      direction: rtl;
      justify-content: space-evenly;
      background: linear-gradient(180deg, rgba(69,12,0,1) 0%, rgba(50,6,0,0.9019257361147583) 50%, rgba(69,12,0,0.8543066884957108) 100%);
      border-radius: 5% 0 5% 0;
      box-shadow: 0 0 5px 2px rgb(255, 255, 255);
      width:45dvw;
      height:30dvh;
      align-items: center;
      margin:3% auto;
  }
  .city{
      font-family: "Horev";
      font-size: 5rem;
      color: #ffffff;
      text-align: center;
      
  }
  .day{
      font-family: "Horev";
      font-size: 2.5rem;
      color: #ffffff;
      margin-left:1rem;
      text-align: center;
  }
  .hour{
      font-family: "Horev";
      font-size: 2rem;
      color: #ffffff;
      text-align: center;
  }
  .data{
      display: flex;
      flex-direction: row; 
      align-items: center;
      justify-content: space-evenly;
  }
}
@media only screen and (min-width: 1150px) and (max-width: 1400px) {


  .group{
      display: flex;
      flex-direction: row;
      direction: rtl;
      justify-content: space-evenly;
      background: linear-gradient(180deg, rgba(69,12,0,1) 0%, rgba(50,6,0,0.9019257361147583) 50%, rgba(69,12,0,0.8543066884957108) 100%);
      border-radius: 5% 0 5% 0;
      box-shadow: 0 0 5px 2px rgb(255, 255, 255);
      width:35dvw;
      height:30dvh;
      align-items: center;
      margin:3% auto;
  }
  .city{
      font-family: "Horev";
      font-size: 4.5rem;
      color: #ffffff;
      text-align: center;
      
  }
  .day{
      font-family: "Horev";
      font-size: 2.5rem;
      color: #ffffff;
      margin-left:1rem;
      text-align: center;
  }
  .hour{
      font-family: "Horev";
      font-size: 2rem;
      color: #ffffff;
      text-align: center;
  }
  .data{
      display: flex;
      flex-direction: row; 
      align-items: center;
      justify-content: space-evenly;
  }
}