.title{
  font-size: 7rem;
 
  width:65%;
color:black;
  justify-content: center;
  text-align: center;
  margin:0 auto 5%;
  padding-top:5%;
  font-family: "Horev";
}
.svg{
  transform: translateY(-10px);
}
.akordion{
  width: 50%;
   margin:0 auto;
   padding-bottom: 3%;
}
@media only screen and (max-width: 350px) {

  .title{
      font-size: 3.5rem;
     
      width:100%;
   color:black;
      justify-content: center;
      text-align: center;
      margin:3% auto 5%;
      padding-top:5%;
      font-family: "Horev";
  }
}
@media only screen and (min-width: 350px) and (max-width: 450px) {

  .title{
      font-size: 4.5rem;
     
      width:100%;
   color:black;
      justify-content: center;
      text-align: center;
      margin:0 auto 5%;
      padding-top:5%;
      font-family: "Horev";
  }
}
@media only screen and (min-width: 450px) and (max-width: 550px) {

  .title{
      font-size: 5rem;
     
      width:100%;
   color:black;
      justify-content: center;
      text-align: center;
      margin:0 auto 5%;
      padding-top:5%;
      font-family: "Horev";
  }
}
@media only screen and (min-width: 550px) and (max-width: 650px) {

  .title{
      font-size: 5.5rem;
     
      width:100%;
   color:black;
      justify-content: center;
      text-align: center;
      margin:0 auto 5%;
      padding-top:5%;
      font-family: "Horev";
  }
}
@media only screen and (min-width: 650px) and (max-width: 750px) {

  .title{
      font-size:6rem;
     
      width:100%;
   color:black;
      justify-content: center;
      text-align: center;
      margin:0 auto 5%;
      padding-top:5%;
      font-family: "Horev";
  }
}


@media only screen and (max-width: 750px) {

  .akordion{
      width: 85%;
       margin: 5% auto;
  }


}
@media only screen and (min-width: 750px) and (max-width: 1050px) {

  .akordion{
      width: 70%;
       margin: 5% auto;
  }

}
@media only screen and (min-width: 1050px) and (max-width: 1250px) {

  .akordion{
      width: 65%;
       margin: 4% auto;
  }

}

