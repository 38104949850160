.row{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.title{

    color:black;
    margin:4% auto;
    font-size: 8rem;
    font-family: "Horev";
    letter-spacing: 0.1rem;
    text-align: center;
    }
    .bold{
        display: inline;
        color: #9f0000;
      }
    .subTitle{

        color:black;
        margin:4% auto;
        font-size: 4rem;
        font-family: "Horev";
        text-align: center;
        }
        .side{

            color:black;
            margin:4% auto;
            font-size: 4rem;
            font-family: "Horev";
        width:55%;
            text-align: center;
            }
        .sentence{

            color:black;
            margin:4% auto;
            font-size: 1.5rem;
            font-family: "HeeboR";
            width:55%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-evenly;
            text-align: center;
            direction: rtl;
            }
 
            .left{
                flex:1;
            }
            .right{
                flex:1;
            }
    .checkIcon {
        color: green; /* Green color for the checkmark */
        margin-left: 20px; /* Adjust the spacing as needed */
      }
      
      .timesIcon {
        color: red; /* Red color for the "x" */
        margin-left: 20px; /* Adjust the spacing as needed */
      }

    @media only screen and (max-width: 350px){
        
        .row{
            display: flex;
            flex-direction: column;
  
        }
        
        .title{
        
            color:black;
            margin:2% auto;
            font-size: 4rem;
            font-family: "Horev";
 
            text-align: center;
            }
    
            .subTitle{
        
                color:black;
                margin:4% auto;
                font-size: 2.5rem;
                font-family: "Horev";
       
                text-align: center;
                }
                .sentence{
        
                    color:black;
                    margin:4% auto;
                    font-size: 1rem;
                    font-family: "HeeboR";
                    width:95%;
                    display: flex;
                    flex-direction:row;
                    align-items: center;
        
                    text-align: center;
                    }
                    .left{
                     width:90%;
                     margin: 2% auto;
                    }
                    .right{
                     width:90%;
                     margin: 2% auto;
                    }
            .checkIcon {
                color: green; /* Green color for the checkmark */
                margin-left: 20px; /* Adjust the spacing as needed */
              }
              
              .timesIcon {
                color: red; /* Red color for the "x" */
                margin-left: 20px; /* Adjust the spacing as needed */
              }
    }
    @media only screen  and (min-width: 350px) and (max-width: 450px){
        
        .row{
            display: flex;
            flex-direction: column;
  
        }
        
        .title{
        
            color:black;
            margin:2% auto;
            font-size: 4rem;
            font-family: "Horev";
 
            text-align: center;
            }
         
            .subTitle{
        
                color:rgb(255, 179, 146);
                margin:4% auto;
                font-size: 2.5rem;
                font-family: "Horev";
       
                text-align: center;
                }
                .sentence{
        
                    color:black;
                    margin:4% auto;
                    font-size: 1.1rem;
                    font-family: "HeeboR";
                    width:80%;
                    display: flex;
                    flex-direction:row;
                    align-items: center;
        
                    text-align: center;
                    }
                    .left{
                     width:90%;
                     margin: 2% auto;
                    }
                    .right{
                     width:90%;
                     margin: 2% auto;
                    }
            .checkIcon {
                color: green; /* Green color for the checkmark */
                margin-left: 20px; /* Adjust the spacing as needed */
              }
              
              .timesIcon {
                color: red; /* Red color for the "x" */
                margin-left: 20px; /* Adjust the spacing as needed */
              }
    }
    @media only screen  and (min-width: 450px) and (max-width: 550px){
        
        .row{
            display: flex;
            flex-direction: column;
  
        }
        
        .title{
        
            color:black;
            margin:2% auto;
            font-size: 5rem;
            font-family: "Horev";
 width:95%;
            text-align: center;
            }
   
            .subTitle{
        
                color:rgb(255, 179, 146);
                margin:4% auto;
                font-size: 3rem;
                font-family: "Horev";
       
                text-align: center;
                }
                .sentence{
        
                    color:black;
                    margin:4% auto;
                    font-size: 1.2rem;
                    font-family: "HeeboR";
                    width:80%;
                    display: flex;
                    flex-direction:row;
                    align-items: center;
        
                    text-align:center;
                    }
                    .left{
                     width:90%;
                     margin: 2% auto;
                    }
                    .right{
                     width:90%;
                     margin: 2% auto;
                    }
            .checkIcon {
                color: green; /* Green color for the checkmark */
                margin-left: 20px; /* Adjust the spacing as needed */
              }
              
              .timesIcon {
                color: red; /* Red color for the "x" */
                margin-left: 20px; /* Adjust the spacing as needed */
              }
    }
    @media only screen  and (min-width: 550px) and (max-width: 650px){
        
        .row{
            display: flex;
            flex-direction: column;
  
        }
        
        .title{
        
            color:black;
            margin:2% auto;
            font-size: 5.5rem;
            font-family: "Horev";
            width:95%;
            text-align: center;
            }
         
            .subTitle{
        
                color:rgb(255, 179, 146);
                margin:4% auto;
                font-size: 3.5rem;
                font-family: "Horev";
       
                text-align: center;
                }
                .sentence{
        
                    color:black;
                    margin:4% auto;
                    font-size: 1.2rem;
                    font-family: "HeeboR";
                    width:75%;
                    display: flex;
                    flex-direction:row;
                    align-items: center;
        
                    text-align: center;
                    }
                    .left{
                     width:80%;
                     margin: 2% auto;
                    }
                    .right{
                     width:80%;
                     margin: 2% auto;
                    }
            .checkIcon {
                color: green; /* Green color for the checkmark */
                margin-left: 20px; /* Adjust the spacing as needed */
              }
              
              .timesIcon {
                color: red; /* Red color for the "x" */
                margin-left: 20px; /* Adjust the spacing as needed */
              }
    }
    @media only screen  and (min-width: 650px) and (max-width: 850px){
        
        .row{
            display: flex;
            flex-direction: column;
  
        }
        
        .title{
        
            color:black;
            margin:2% auto;
            font-size: 6rem;
            font-family: "Horev";
            width:95%;
            text-align: center;
            }
     
            .subTitle{
        
                color:rgb(255, 179, 146);
                margin:4% auto;
                font-size: 4rem;
                font-family: "Horev";
       
                text-align: center;
                }
                .sentence{
        
                    color:black;
                    margin:4% auto;
                    font-size: 1.3rem;
                    font-family: "HeeboR";
                    width:75%;
                    display: flex;
                    flex-direction:row;
                    align-items: center;
        
                    text-align: center;
                    }
                    .left{
                     width:70%;
                     margin: 2% auto;
                    }
                    .right{
                     width:70%;
                     margin: 2% auto;
                    }
            .checkIcon {
                color: green; /* Green color for the checkmark */
                margin-left: 20px; /* Adjust the spacing as needed */
              }
              
              .timesIcon {
                color: red; /* Red color for the "x" */
                margin-left: 20px; /* Adjust the spacing as needed */
              }
    }
    @media only screen  and (min-width: 850px) and (max-width: 1050px){
        .row{
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
        }
        
        .title{
        
            color:black;
            margin:4% auto;
            font-size: 7rem;
            font-family: "Horev";
 
            text-align: center;
            }
            .bold{
                display: inline;
                color: #9f0000;
              }
            .subTitle{
        
                color:black;
                margin:4% auto;
                font-size: 3.5rem;
                font-family: "Horev";
                text-align: center;
                }
                .side{
        
                    color:black;
                    margin:4% auto;
                    font-size: 3.5rem;
                    font-family: "Horev";
                width:65%;
                    text-align: center;
                    }
                .sentence{
        
                    color:black;
                    margin:4% auto;
                    font-size: 1.2rem;
                    font-family: "HeeboR";
                    width:55%;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-evenly;
                    text-align: center;
                    direction: rtl;
                    }
         
                    .left{
                        flex:1;
                    }
                    .right{
                        flex:1;
                    }
            .checkIcon {
                color: green; /* Green color for the checkmark */
                margin-left: 20px; /* Adjust the spacing as needed */
              }
              
              .timesIcon {
                color: red; /* Red color for the "x" */
                margin-left: 20px; /* Adjust the spacing as needed */
              }
      
    }
    @media only screen  and (min-width: 1050px) and (max-width: 1250px){
        .row{
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
        }
        
        .title{
        
            color:black;
            margin:4% auto;
            font-size: 7rem;
            font-family: "Horev";
 
            text-align: center;
            }
            .bold{
                display: inline;
                color: #9f0000;
              }
            .subTitle{
        
                color:black;
                margin:4% auto;
                font-size: 3rem;
                font-family: "Horev";
                text-align: center;
                }
                .side{
        
                    color:black;
                    margin:4% auto;
                    font-size: 3.5rem;
                    font-family: "Horev";
                width:65%;
                    text-align: center;
                    }
                .sentence{
        
                    color:black;
                    margin:4% auto;
                    font-size: 1.2rem;
                    font-family: "HeeboR";
                    width:55%;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-evenly;
                    text-align: center;
                    direction: rtl;
                    }
         
                    .left{
                        flex:1;
                    }
                    .right{
                        flex:1;
                    }
            .checkIcon {
                color: green; /* Green color for the checkmark */
                margin-left: 20px; /* Adjust the spacing as needed */
              }
              
              .timesIcon {
                color: red; /* Red color for the "x" */
                margin-left: 20px; /* Adjust the spacing as needed */
              } 
    }
    @media only screen  and (min-width: 1250px) and (max-width: 1400px){
        .row{
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
        }
        
        .title{
        
            color:black;
            margin:4% auto;
            font-size: 7.5rem;
            font-family: "Horev";
 
            text-align: center;
            }
            .bold{
                display: inline;
                color: #9f0000;
              }
            .subTitle{
        
                color:black;
                margin:4% auto;
                font-size: 3rem;
                font-family: "Horev";
                text-align: center;
                }
                .side{
        
                    color:black;
                    margin:4% auto;
                    font-size: 3.5rem;
                    font-family: "Horev";
                width:65%;
                    text-align: center;
                    }
                .sentence{
        
                    color:black;
                    margin:4% auto;
                    font-size: 1.1rem;
                    font-family: "HeeboR";
                    width:60%;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-evenly;
                    text-align: center;
                    direction: rtl;
                    }
         
                    .left{
                        flex:1;
                    }
                    .right{
                        flex:1;
                    }
            .checkIcon {
                color: green; /* Green color for the checkmark */
                margin-left: 20px; /* Adjust the spacing as needed */
              }
              
              .timesIcon {
                color: red; /* Red color for the "x" */
                margin-left: 20px; /* Adjust the spacing as needed */
              } 
    }
